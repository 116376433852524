//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { generateSubcontractorInvoiceRequestBody } from "./invoiceUtils";
import { SERVICES, add, update } from "../../../../redux/apis/global";
import { CalendarModalBody } from "../../../client/NewPlanning/Calendar.styles";
import Modal from "../../../../components/Feedback/Modal";
import InputController from "../../../../form/InputController";
import currencies from "../currencies";
import SelectController from "../../../../form/SelectController";
import { Typography } from "@material-ui/core";
import { TableViews } from "../../../Tables/AntTables/AntTables";
import DatePickerController from "../../../../form/DatePickerController";
import { DiffHours, timeZoneMoment } from "../../../../redux/apis/utils";
import { Shift, Holidays } from "../../../../redux/apis/types";

import moment from "moment-timezone";
import SubcontractorInvoiceTotal from "./SubcontractorInvoiceTotal";
import { getSurchargeData } from "../../invoices/surchargeUtils";

type Props = {
  modalVisible: boolean;
  holidays?: Holidays[];
  onClose: () => void;
  onCreate?: (data: any) => void;
  data: any;
};

export default function ({
  modalVisible,
  onClose,
  onCreate,
  data,
  holidays,
}: Props) {
  const [applyPurchaseCost, setApplyPurchaseCost] = useState();
  const [isTableChange, setTableChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const currencyList = useMemo(() => {
    return Object.keys(currencies);
  }, []);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      shifts: data?.data?.work,
      vat: data?.data?.vat || 21,
      amount: data?.data?.amount || 0,
      total_amount: data?.data?.total || 0,
      currency: data?.currency || "EUR",
      invoice_number: data?.invoice_number || "",
      invoice_date: moment(data?.invoice_date) || moment(),
      total_purchase_cost: 0,
      surcharge: 0,
    },
  });
  const { handleSubmit, watch, setValue } = methods;
  const amount = watch("amount");
  const vat = watch("vat");
  const shifts = watch("shifts");
  const apply_all_purchase_cost = watch("apply_all_purchase_cost");

  useEffect(() => {
    const setDefaultShifts = async () => {
      if (!data?.id) {
        const resp = await getSurchargeData(
          data?.data?.work,
          applyPurchaseCost
        );
        setValue("shifts", resp);
      }
    };
    setDefaultShifts();
  }, []);
  useEffect(() => {
    if (!!vat && !!amount) {
      setValue(
        "total_amount",
        (
          parseFloat(amount) * parseFloat(vat) * 0.01 +
          parseFloat(amount)
        ).toFixed(2)
      );
    }
  }, [vat, amount]);

  const visible = modalVisible ? true : false;
  if (!visible) return null;

  const onSave = async (obj: any) => {
    setLoading(true);

    if (!obj.shifts[0].subcontractor_id) {
      alert("Subcontractor is missing!");
      return;
    }

    if (data?.id) {
      const body = generateSubcontractorInvoiceRequestBody(obj);
      const request = {
        type: "debit",
        ...body,
        id: data?.id,
      };

      const resp = await update(
        `${SERVICES.INVOICE}/subcontractor`,
        null,
        request
      );
      setLoading(false);
      if (resp?.message) {
        alert(resp?.message);
      } else onCreate();
    } else {
      const body = generateSubcontractorInvoiceRequestBody(obj);
      const request = {
        type: "debit",
        ...body,
      };

      const resp = await add(`${SERVICES.INVOICE}`, request);
      setLoading(false);
      if (resp) {
        onCreate();
      }
    }

    // if (resp && onCreate) {
    //   onCreate({
    //     invoice_number: request.invoice_number,
    //     invoice_id: resp.invoice_id,
    //     branch_id: data[0]?.branch_id,
    //     client_id: data[0]?.client_id,
    //   });
    // }
  };

  const columns = [
    {
      title: "Date",
      render: (text: string, record: Shift) => (
        <div style={{ fontWeight: "bold" }}>
          {moment(record.start).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: "Client",
      render: (text: string, record: Shift) => (
        <div>{record?.planning?.branch?.name}</div>
      ),
    },
    {
      title: "Client Invoice nr.",
      render: (text: string, record: Shift) => (
        <div>{record.planning.invoice?.invoice_number || "N/A"}</div>
      ),
    },
    {
      title: "Location",
      render: (text: string, record: Shift) => (
        <div>{record.planning.location?.name || "N/A"}</div>
      ),
    },
    {
      title: "From",
      render: (text: string, record: Shift) => (
        <div>
          {timeZoneMoment(
            record.start,
            record?.planning.location?.timezone
          ).format("HH:mm")}
        </div>
      ),
    },
    {
      title: "Till",
      render: (text: string, record: Shift) => (
        <div>
          {timeZoneMoment(
            record.end,
            record?.planning.location?.timezone
          ).format("HH:mm")}
        </div>
      ),
    },
    {
      title: "Hours",
      render: (text: string, record: Shift) => (
        <div>{record.total_hours.toFixed(2)}</div>
      ),
    },
    {
      title: "Nbr Agents",
      render: (text: string, record: Shift) => (
        <div>{record.number_of_agents}</div>
      ),
    },
    {
      title: "Sales costs per hour",
      render: (text: string, record: Shift) => (
        <div>
          {record.sales_cost_per_hour.toFixed(2)} {record.salesCurrency}
        </div>
      ),
    },
    {
      title: "Purchase costs per hour",
      render: (text: string, record: Shift, index: number) => (
        <div>
          <InputController
            onChange={() => setTableChange(Math.random())}
            name={`shifts.${index}.purchase_cost_per_hour`}
            placeholder="type here"
            rules={{
              required: true,
            }}
            type="phone"
          />
        </div>
      ),
    },
    {
      title: "Marge per hour",
      render: (text: string, record: Shift, index: number) => {
        const purchaseCostPerHour = watch(
          `shifts.${index}.purchase_cost_per_hour`
        );
        return (
          <div>
            {(record.sales_cost_per_hour - purchaseCostPerHour).toFixed(2)}{" "}
            {record.salesCurrency}
          </div>
        );
      },
    },
    {
      title: "Total Sales costs",
      render: (text: string, record: Shift) => (
        <div>
          {record.total_sales_cost.toFixed(2)} {record.salesCurrency}
        </div>
      ),
    },
    {
      title: "Purchase costs",
      render: (text: string, record: Shift, index: number) => {
        const purchaseCost = watch(`shifts.${index}.purchase_cost_per_hour`);

        return (
          <div>
            {purchaseCost
              ? parseFloat(purchaseCost) *
                record.total_hours *
                record.number_of_agents
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Surcharge",
      render: (text: string, record: Shift, index: number) => {
        return (
          <div>
            <InputController
              onChange={() => setTableChange(Math.random())}
              name={`shifts.${index}.surcharge`}
              placeholder="type here"
              rules={{
                required: true,
              }}
              type="phone"
            />
          </div>
        );
      },
    },
    {
      title: "Total Purchase Cost",
      render: (text: string, record: Shift, index: number) => {
        const purchaseCost =
          watch(`shifts.${index}.purchase_cost_per_hour`) || 0;
        const surchargeCost = watch(`shifts.${index}.surcharge`) || 0;
        const currency = record.purchaseCurrency;
        const hours = record.total_hours;
        const totalPurchaseCost =
          parseFloat(purchaseCost) * hours * record.number_of_agents +
          parseFloat(surchargeCost);
        return isNaN(Number(totalPurchaseCost)) ? (
          <div>N/A</div>
        ) : (
          <div>
            {totalPurchaseCost.toFixed(2)} {currency}
          </div>
        );
      },
    },
    {
      title: "Total Marge",
      render: (text: string, record: Shift, index: number) => {
        const currency = record.salesCurrency;

        const purchaseCost =
          watch(`shifts.${index}.purchase_cost_per_hour`) || 0;
        const surchargeCost = watch(`shifts.${index}.surcharge`) || 0;
        const hours = record.total_hours;
        const totalPurchaseCost =
          parseFloat(purchaseCost) * hours * record.number_of_agents +
          parseFloat(surchargeCost);

        const salesCost = parseFloat(record.total_sales_cost);
        const totalMarge = salesCost - totalPurchaseCost;
        return isNaN(Number(totalMarge)) ? (
          <div>N/A</div>
        ) : (
          <div>
            {totalMarge.toFixed(2)} {currency}
          </div>
        );
      },
    },
    {
      title: "Marge %",
      render: (text: string, record: Shift, index: number) => {
        const purchaseCost =
          watch(`shifts.${index}.purchase_cost_per_hour`) || 0;
        const surchargeCost = watch(`shifts.${index}.surcharge`) || 0;
        const hours = record.total_hours;
        const totalPurchaseCost =
          parseFloat(purchaseCost) * hours * record.number_of_agents +
          parseFloat(surchargeCost);

        const salesCost = parseFloat(record.total_sales_cost);
        const totalMarge = salesCost - totalPurchaseCost;

        const margePercentage = (totalMarge / salesCost) * 100;
        return isNaN(Number(margePercentage)) ? (
          <div>N/A</div>
        ) : (
          <div>{margePercentage.toFixed(2)}%</div>
        );
      },
    },
  ];

  const onApplyPurchaseCost = () => {
    // setApplyPurchaseCost(apply_all_purchase_cost);
    const copy = _.cloneDeep(shifts);
    for (let i = 0; i < copy.length; i++) {
      copy[i].purchase_cost_per_hour = apply_all_purchase_cost;
    }
    setValue("shifts", copy);
  };

  return (
    <div>
      <Modal
        title={"Subcontractor Invoice"}
        visible={visible}
        confirmLoading={loading}
        onOk={handleSubmit(onSave)}
        onCancel={onClose}
        okText={"Save"}
        cancelText="Cancel"
        width="100%"
        style={{ top: 20 }}
      >
        <CalendarModalBody>
          <FormProvider {...methods}>
            <form>
              <Row gutter={20}>
                <Col md={3} sm={24} xs={24}>
                  <b>Invoice Date</b>{" "}
                  <DatePickerController
                    rules={{ required: true }}
                    name="invoice_date"
                  />
                </Col>
                <Col md={3} sm={24} xs={24}>
                  <InputController
                    name="invoice_number"
                    style={{ width: "100%" }}
                    rules={{ required: true }}
                    label="Invoice Number"
                  />
                </Col>
                <Col md={3} sm={24} xs={24}>
                  <SelectController
                    name="currency"
                    options={currencyList}
                    rules={{ required: true }}
                    label="Currency"
                  />
                </Col>

                <Col md={3} sm={24} xs={24}>
                  <InputController
                    name="amount"
                    type="number"
                    rules={{ required: true }}
                    label="Invoice Purchase cost"
                  />
                </Col>

                <Col md={3} sm={24} xs={24}>
                  <InputController
                    name="vat"
                    type="number"
                    rules={{ required: true }}
                    label="VAT %"
                  />
                </Col>
                <Col md={3} sm={24} xs={24}>
                  <InputController
                    name="total_amount"
                    type="number"
                    rules={{ required: true }}
                    label="Total amount"
                  />
                </Col>
              </Row>

              <Row gutter={40} style={{ margin: 10 }}>
                <div>
                  <Typography style={{ marginRight: 5 }} variant="h6">
                    Shift(s) History
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 10,
                      marginBottom: 10,
                    }}
                  >
                    <InputController
                      name="apply_all_purchase_cost"
                      type="number"
                      placeholder="Purchase cost per hour"
                      rules={{ required: false }}
                      style={{ width: 200 }}
                    />
                    <Button onClick={onApplyPurchaseCost} type="primary">
                      Apply all
                    </Button>
                  </div>

                  <TableViews.SimpleView
                    style={{ marginBottom: 10 }}
                    dataSource={data?.data?.work || []}
                    columns={columns}
                  />
                </div>
              </Row>
              <SubcontractorInvoiceTotal
                shouldRecalculate={isTableChange}
                total={amount}
                shifts={shifts}
              />
            </form>
          </FormProvider>
        </CalendarModalBody>
      </Modal>
    </div>
  );
}
