// @ts-ignore
import { Row, Tag } from "antd";
import { Typography } from "@material-ui/core";
import { TableViews } from "../../../Tables/AntTables/AntTables";
import React, { useMemo } from "react";
import { Shift } from "../../../../redux/apis/types";
import {
  getInvoiceShiftClientRate,
  getInvoiceShiftSubcRate,
} from "../../invoices/invoiceUtils";
import { DiffHours } from "../../../../redux/apis/utils";
import { useFormContext } from "react-hook-form";

type Props = {
  total?: number;
  shifts: Shift[];
  source?: string;
  shouldRecalculate?: boolean;
};

const SubcontractorInvoiceTotal = ({
  total,
  shifts,
  source,
  shouldRecalculate,
}: Props) => {
  const context = useFormContext();
  const { formState } = context;

  const getTotalSubcontractorInvoice = (
    shifts: Shift[],
    total: number | undefined
  ) => {
    let data = {
      sales_costs: 0,
      purchase_cost: 0,
      total_purchase_cost: 0,
      expected_total_purchase_cost: 0,
      total_sales_costs: 0,
      marge: 0,
      total_marge: 0,
      total_hours: 0,
      surcharge: 0,
      currency: "EUR",
    };

    if (shifts[0].planning?.branch_rate?.currency)
      data["currency"] = shifts[0].planning?.branch_rate?.currency;

    for (let i = 0; i < shifts.length; i++) {
      let surchargeCost = 0;
      let purchaseCost = 0;
      if (context) {
        purchaseCost = parseFloat(
          context.watch(`shifts.${i}.purchase_cost_per_hour`)
        );
      } else {
        purchaseCost = shifts[i].purchase_cost_per_hour;
      }
      if (context) {
        surchargeCost = parseFloat(context.watch(`shifts.${i}.surcharge`));
      } else {
        surchargeCost = shifts[i].surcharge;
      }

      data["sales_costs"] += shifts[i].sales_cost_per_hour;
      data["total_hours"] += shifts[i].total_hours;
      data["total_sales_costs"] += shifts[i].total_sales_cost;

      if (purchaseCost) {
        data["purchase_cost"] += purchaseCost;
        data["surcharge"] += surchargeCost;
        data["expected_total_purchase_cost"] +=
          purchaseCost * shifts[i].total_hours * shifts[i].number_of_agents +
          surchargeCost;
        data["marge"] +=
          shifts[i].sales_cost_per_hour - shifts[i].purchase_cost_per_hour;
      }
    }

    data["total_marge"] =
      data["total_sales_costs"] - data["expected_total_purchase_cost"];

    if (total) {
      data["total_marge"] = data["total_sales_costs"] - total;
    }

    return [data];
  };

  const data = useMemo(() => {
    return getTotalSubcontractorInvoice(shifts, total);
  }, [shifts, total, shouldRecalculate]);

  const columns = [
    {
      title: "Total Hours",
      render: (text: string, record: any) => (
        <div>{record.total_hours.toFixed(2)}</div>
      ),
    },
    {
      title: "Total Sales costs",
      render: (text: string, record: any) => (
        <div>
          {record.total_sales_costs.toFixed(2)} {record.currency}
        </div>
      ),
    },
    {
      title: "Expected Purchase costs",
      render: (text: string, record: any, index: number) => (
        <div>
          {record.expected_total_purchase_cost.toFixed(2)} {record.currency}
        </div>
      ),
    },
    {
      title: "Surcharge",
      render: (text: string, record: any, index: number) => (
        <div>
          {record.surcharge.toFixed(2)} {record.currency}
        </div>
      ),
    },
    {
      title: "Invoice Purchase cost",
      render: (text: string, record: any, index: number) => (
        <div>{total ? total + " " + record.currency : "N/A"}</div>
      ),
      hidden: source === "overview",
    },
    {
      title: "Total Marge",
      render: (text: string, record: any, index: number) => (
        <div>
          {record.total_marge.toFixed(2)} {record.currency}
        </div>
      ),
    },
    {
      title: "Status",
      hidden: source === "overview",
      render: (text: string, record: any, index: number) => {
        if (!total) return <Tag color="warning">Unknown</Tag>;

        // const different = record.total_purchase_cost - total;
        if (record.expected_total_purchase_cost >= total) {
          return <Tag color="success">Matched</Tag>;
        }

        return <Tag color="error">Not Matched</Tag>;
      },
    },
  ];

  return (
    <Row gutter={40} style={{ marginTop: 10, justifyContent: "center" }}>
      <Typography style={{ marginRight: 5, alignSelf: "center" }} variant="h6">
        Total
      </Typography>
      <TableViews.SimpleView
        style={{ marginBottom: 10 }}
        dataSource={data}
        columns={columns.filter((item) => !item.hidden)}
      />
    </Row>
  );
};

export default SubcontractorInvoiceTotal;
